import {
  changeDefaultWorkspaceUrl,
  fetchOnboardingWorkspacesUrl,
  fetchWorkspaceLogoSuggessionsUrl,
  fetchWorkspacesUrl,
  fetchWorkspaceUrl,
  removeWorkspaceUrl,
  saveWorkspaceUrl,
  slugAvailableWorkspaceURL,
  uploadWorkspaceLogoUrl,
  onboardingCompleteURL,
  onboardingStepsURL,
  onboardingWidgetURL,
  fetchUserWorkspacesUrl,
} from '@src/modules/setting/config/api-utils'
import { mapActions, mapGetters } from 'vuex'
import debounce from 'lodash/debounce'
import sortBy from 'lodash.sortby'
import { workspaceTypes } from '@src/modules/setting/store/states/mutation-types'
import { composer } from '@src/modules/composer/store/composer-mutation-type'
import proxy, { getProxyCancelToken } from '@src/modules/common/lib/http-common'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { EventBus } from '@common/lib/event-bus'
import { APPROVER_ALLOWED_ROUTES } from '@common/constants/common-attributes'
import { WORKSPACE_ROLES } from '@common/constants/composer'

export const workspaceMixin = {
  data() {
    return{
      isWorkspaceLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'getWorkspaces',
      'getWorkspaceDetails',
      'getProfile',
      'getPlan',
      'getOnboardingWorkspaces',
      'getActiveWorkspace',
      'getPublicationFolderStatus',
    ]),
    /**
     * this will return onboarding steps value in persentage
     */
    onBoardingProgressValue() {
      let totalSteps = 4
      let doneSteps = 0

      if (this.getActiveWorkspace.onboarding_steps) {
        if (
          this.getActiveWorkspace.onboarding_steps.connect_social_account?.status
        ) {
          doneSteps += 1
        }

        if (this.getActiveWorkspace.onboarding_steps.create_first_post?.status) {
          doneSteps += 1
        }

        if (this.getActiveWorkspace.onboarding_steps.content_category?.status) {
          doneSteps += 1
        }

        if (this.getActiveWorkspace.onboarding_steps.discover_content?.status) {
          doneSteps += 1
        }

        if (
          this.getPlan.subscription.limits &&
          this.getPlan.subscription.limits.members > 0
        ) {
          totalSteps += 1

          if (this.getActiveWorkspace.onboarding_steps.invite_team?.status) {
            doneSteps += 1
          }
        }

        return (doneSteps / totalSteps) * 100
      }

      return -1
    },

    /**
     * this will filter workspaces base on search
     */
    filteredWorkspaces() {
      let workspaces = []
      const searchedValue = this.getWorkspaces.search
      if (searchedValue) {
        const searchedLowerCase = searchedValue.toLowerCase()
        workspaces = this.getWorkspaces.items.filter(
          (item) =>
            item.workspace.name.toLowerCase().indexOf(searchedLowerCase) >= 0 ||
            item.workspace.slug.toLowerCase().indexOf(searchedLowerCase) >= 0
        )
      } else {
        workspaces = this.getWorkspaces.items
      }
      return sortBy(workspaces, function (item) {
        return [item.workspace.name.toLowerCase()]
      })
    },
  },
  methods: {
    ...mapActions([
      'setFetchWorkspacesLoader',
      'setUploadWorkspaceLogoLoader',
      'setFetchWorkspaceLogoSuggessionsLoader',
      'setSaveWorkspaceLoader',
      'setConfirmActionStage',
      'setRemoveWorkspaceLoader',
      'setFetchWorkspaceLoader',
      'setChangeDefaultWorkspaceLoader',
      'setWorkspacesLimit',
      'setOnboardingWorkspaces',
      'setOnboardingTeamIntilize',
      'fetchDiscover',
      'fetchSocialAccounts',
      'fetchBlogs',
      'SET_ONBOARDING_STEPS',
    ]),

    filteredWorkspacesOwnedBy(me) {
      let workspaces = []
      const searchedValue = this.getWorkspaces.search
      if (searchedValue) {
        const searchedLowerCase = searchedValue.toLowerCase()
        workspaces = this.getWorkspaces.items.filter(
          (item) =>
            item.workspace.name.toLowerCase().indexOf(searchedLowerCase) >= 0 ||
            item.workspace.slug.toLowerCase().indexOf(searchedLowerCase) >= 0
        )
      } else {
        workspaces = this.getWorkspaces.items
      }

      const ownedByMe = []
      const ownedByOthers = []
      for (let x = 0; x < workspaces.length; x++) {
        const workspace = workspaces[x]

        if (workspace.workspace.user_id === this.getProfile._id) {
          ownedByMe.push(workspace)
        } else {
          ownedByOthers.push(workspace)
        }
      }

      const arrayToUse = me ? ownedByMe : ownedByOthers
      return sortBy(arrayToUse, function (item) {
        return [item.workspace.name.toLowerCase()]
      })
    },

    /**
     * reset workspace details and show save page
     */
    createWorkspaceModal() {
      this.getWorkspaceDetails.name = ''
      this.getWorkspaceDetails.slug = ''
      this.getWorkspaceDetails.logo = ''

      // let result = this.checkSubscriptionLimits('workspaces')
      // if (result) {
      //   return
      // }

      this.$bvModal.show('create-workspace-modal')

      this.$store.commit(workspaceTypes.SET_WORKSPACE, null)
      // this.$router.push({ 'name': 'createWorkspace' })

      console.debug('Method::createWorkspace')
    },
    createWorkspace() {
      // console.debug('Method::createWorkspace')
      // let result = this.checkSubscriptionLimits('workspaces')
      // if (result) {
      //   return
      // }
      this.$store.commit(workspaceTypes.SET_WORKSPACE, null)
      this.$router.push({ name: 'createWorkspace' })
    },

    /**
     * Get workspaces.
     */
    async fetchWorkspacesOld(process = false, reset = true, store = null, route = null, router = null, slug = null) {
      console.debug('Method:fetchWorkspaces')

        if (store) {
            this.$store = store
        }
        if (route) {
            this.$route = route
        }
        if (router) {
            this.$router = router
        }

      this.$store.dispatch('setFetchWorkspacesLoader', true)
      return await proxy
        .post(fetchWorkspacesUrl, {"source": "web"})
        .then((response) => {
          this.$store.commit(workspaceTypes.SET_WORKSPACE_LOADED, true)
          this.$store.dispatch('setFetchWorkspacesLoader', false)
          if (response.data.status === true) {
            this.$store.commit(
              workspaceTypes.SET_WORKSPACE_ITEMS,
              response.data.workspaces
            )
            if (process === true) {
              this.processActiveWorkspace(response.data.workspaces, reset)

              if (this.$route.name === 'home') {
                this.$router
                  .push({
                    name: 'dashboard',
                    params: {
                      workspace: this.getWorkspaces.activeWorkspace.slug,
                    },
                  })
                  .catch((error) => {
                    if (error.name !== 'NavigationDuplicated') {
                      throw error
                    }
                  })
              }
            }

            return response.data.workspaces
          }
          return []
        })
        .catch((error) => {
          console.debug('Exception in fetchWorkspaces', error)
          this.$store.dispatch('setFetchWorkspacesLoader', false)
          return []
        })
    },

    async fetchWorkspaces(process = false, reset = true, store = null, route = null, router = null, slug = null) {
      console.debug('Method:fetchWorkspaces')

      if (store) {
        this.$store = store
      }
      if (route) {
        this.$route = route
      }
      if (router) {
        this.$router = router
      }

      if (!slug) {
        slug = this.$route.params.workspace ?? 'is_default'
      }

      this.$store.dispatch('setFetchWorkspacesLoader', true)
      try {
        const response = await proxy.post(fetchUserWorkspacesUrl, {"source": "web", "slug": slug})
        await this.changeWorkspace(null, '',  slug, this.$route?.hash)
        this.$store.commit(workspaceTypes.SET_WORKSPACE_LOADED, true)

        if (response.data.status === true) {
          const workspaces = response.data.workspaces
          this.$store.commit(workspaceTypes.SET_WORKSPACE_ITEMS, workspaces)

          if (process) {
            // await this.processActiveWorkspace(workspaces, reset)
            if (this.$route.name === 'home') {
              await this.navigateToDashboard()
            }
          }

          return workspaces
        }
        return []
      } catch (error) {
        console.debug('Exception in fetchWorkspaces', error)
        return []
      } finally {
        this.$store.dispatch('setFetchWorkspacesLoader', false)
      }
    },

    /**
     * Get workspace by id.
     */
    async fetchWorkspace(payload) {
      console.debug('Method:fetchWorkspace')
      return proxy
        .post(fetchWorkspaceUrl, payload)
        .then((response) => {
          if (response.data.status === true) {
            return response.data.item.workspace
          }
          return []
        })
        .catch((error) => {
          console.debug('Exception in fetchWorkspace', error)
          return []
        })
    },

    fetchOnboardingWorkspaces() {
      console.debug('Method:fetchOnboardinhWorkspaces')
      this.setFetchWorkspacesLoader(true)
      this.postRequest(
        fetchOnboardingWorkspacesUrl,
        {},
        (response) => {
          if (response.data.status === true) {
            this.setOnboardingWorkspaces(response.data.workspaces)
          }
          this.setFetchWorkspacesLoader(false)
        },
        (error) => {
          console.debug('Exception in fetchWorkspaces', error)
          this.setFetchWorkspacesLoader(false)
        }
      )
    },

    /**
     * trigger file change for uploading workspace logo
     */
    triggerWorkspaceChangeLogo(id) {
      console.debug('Method:triggerWorkspaceChangeLogo')
      document.getElementById(id).click()
    },

    /**
     * this method will upload workspace logo
     */

    async uploadWorkspaceLogo(event, isNewWorkspace = false) {
      const files = event.target.files || event.dataTransfer.files
      console.debug('Method:uploadWorkspaceLogo' ,  files, isNewWorkspace)

      if(!files?.length){
        return
      }

      const formData = new FormData()
      formData.append('file', files[0])
      if (this.getActiveWorkspace?._id && !isNewWorkspace) {
        formData.append('workspace_id', this.getWorkspaces.activeWorkspace._id)
      }
      this.setUploadWorkspaceLogoLoader(true)

      return await proxy
        .post(uploadWorkspaceLogoUrl, formData)
        .then((response) => {
          if (response.data.status === true) {
            this.getWorkspaceDetails.logo = response.data.media.link
            this.getWorkspaceDetails.logoId = response.data.media._id
          } else if (response.data.message) {
            this.alertMessage(response.data.message, 'error')
          } else {
            this.alertMessage('Unable to upload logo image.', 'error')
          }

          this.setUploadWorkspaceLogoLoader(false)
        })
        .catch((error) => {
          console.debug('Exception in uploadWorkspaceLogo', error)
          event.target.value = ''
          this.setUploadWorkspaceLogoLoader(false)
          this.alertMessage(UNKNOWN_ERROR, 'error')
        })
    },

    /**
     * this method will fetch workspace logo suggession
     */
    fetchWorkspaceLogoSuggessions: debounce(function (event) {
      console.debug('Method:fetchWorkspaceLogoSuggessions')
      this.isSlugAvailableForWorkspace()
      // check for the slug availability.
      this.$store.commit(workspaceTypes.SET_WORKSPACE_LOGO_SUGGESTIONS, [])

      this.$store.commit(
        workspaceTypes.SET_WORKSPACE_LOGO_SUGGESTIONS_LOADER,
        true
      )
      this.setFetchWorkspaceLogoSuggessionsLoader(true)

      proxy
        .post(
          fetchWorkspaceLogoSuggessionsUrl,
          {
            keyword:
              this.getWorkspaceDetails.name.length > 2
                ? this.getWorkspaceDetails.name
                : 'social media',
          },
          getProxyCancelToken(proxy)
        )
        .then(
          (response) => {
            if (response.data.status === true) {
              this.$store.commit(
                workspaceTypes.SET_WORKSPACE_LOGO_SUGGESTIONS,
                response.data.suggessions
              )
            }
            this.setFetchWorkspaceLogoSuggessionsLoader(false)
            this.$store.commit(
              workspaceTypes.SET_WORKSPACE_LOGO_SUGGESTIONS_LOADER,
              false
            )
          },
          (error) => {
            console.debug('Exception in fetchWorkspaceLogoSuggessions', error)
            this.setFetchWorkspaceLogoSuggessionsLoader(false)
            this.$store.commit(
              workspaceTypes.SET_WORKSPACE_LOGO_SUGGESTIONS_LOADER,
              false
            )
          }
        )
    }, 500),

    isSlugAvailableForWorkspace() {
      if (
        this.getWorkspaceSlugValidation &&
        this.getWorkspaceSlugValidation.length < 2
      ) {
        this.$store.commit(
          workspaceTypes.SET_WORKSPACE_SLUG_AVAILABLE_LOADER,
          true
        )
        this.postRequest(
          slugAvailableWorkspaceURL,
          {
            slug: this.getWorkspaceDetails.slug,
          },
          (response) => {
            if (response.data.status) {
              this.$store.commit(
                workspaceTypes.SET_WORKSPACE_SLUG_VALIDATION,
                ''
              )
            } else {
              this.$store.commit(
                workspaceTypes.SET_WORKSPACE_SLUG_VALIDATION,
                'already_used'
              )
            }
            this.$store.commit(
              workspaceTypes.SET_WORKSPACE_SLUG_AVAILABLE_LOADER,
              false
            )
          },
          (response) => {}
        )
      }
    },

    /**
     * this method will save workspace
     */
    async saveWorkspace(
      doClear = true,
      withSuperAdmin = false,
      isUpdating = false
    ) {
      console.debug('Method:saveWorkspace')

      const regexp = /^[\p{L} .0-9]+$/u

      if (
        !this.getWorkspaceDetails.name ||
        this.getWorkspaceDetails.name.trim().length === 0
      ) {
        this.alertMessage('Please enter workspace name.', 'error')
      } else if (!regexp.test(this.getWorkspaceDetails.name)) {
        this.alertMessage(
          'Workspace name should be letters and numbers only (Special characters are not allowed)',
          'error'
        )
      } else if (withSuperAdmin && !this.getWorkspaceDetails.superAdmin) {
        this.alertMessage('Please select workspace super admin.', 'error')
      } else if (!this.getWorkspaceDetails.logo) {
        this.alertMessage('Please upload workspace logo.', 'error')
      } else {
        const ownedWorkspace = this.filteredWorkspacesOwnedBy(true).length > 0

        this.setSaveWorkspaceLoader(true)
        if (!this.getWorkspaceDetails.first_day) {
          this.getWorkspaceDetails.first_day = {}
          this.getWorkspaceDetails.first_day.day = 'Monday'
          this.getWorkspaceDetails.first_day.key = 1
        }

        await this.postRequest(
          saveWorkspaceUrl,
          {
            id: this.getWorkspaceDetails.id,
            name: this.getWorkspaceDetails.name.trim(),
            first_day: {
              day: this.getWorkspaceDetails.first_day.day,
              key: this.getWorkspaceDetails.first_day.key,
            },
            timezone: this.getWorkspaceDetails.timezone,
            logo: this.getWorkspaceDetails.logo,
            logo_id: this.getWorkspaceDetails.logoId
              ? this.getWorkspaceDetails.logoId
              : '',
            workspace_id: ownedWorkspace
              ? this.getWorkspaces.activeWorkspace._id
              : '',
            note: this.getWorkspaceDetails.note,
            super_admin: this.getWorkspaceDetails.superAdmin,
            instagram_posting_method: this.getWorkspaceDetails
              .instagram_posting_method
              ? this.getWorkspaceDetails.instagram_posting_method
              : 'api',
            onboarding: this.getWorkspaceDetails.onboarding,
          },
          async(response) => {
            console.debug('WokspaceResposne=', response)
            if (response.data.status === true) {
              if (doClear) {
                this.getWorkspaceDetails.name = ''
                this.getWorkspaceDetails.slug = ''
                this.getWorkspaceDetails.logo = ''
              }

              if (isUpdating) {
                this.$router.push({ params: { workspace: response.data.slug } })
                this.alertMessage('Workspace successfully updated.', 'success')
              } else {
                this.trackUserMaven('workspace_created')
                this.alertMessage('Workspace successfully saved.', 'success')
              }

              // eslint-disable-next-line
              $('#createWorkspaceModal').modal('hide')

              this.Plan()
              this.$bvModal.hide('create-workspace-modal')
              await this.fetchWorkspaces(true, doClear)
              EventBus.$emit('fetch-latest-workspaces')
              if (this.$route.name === "onboardingWorkspace") {
                this.$router.push({
                  name: "dashboard",
                  params: { workspace: response.data.slug },
                });
              }

              if(this.$route.name.includes('grid_view')) {
                EventBus.$emit('refreshPlannerTableV2')
              }

              // const isNewWorkspace = (this.$route.params.workspace && this.$route.params.workspace !== response.data.slug)

              // this.$router.push({
              //   name: 'dashboard',
              //   params: { workspace: response.data.slug },
              // })
              //
              // console.log('isNewWorkspace In', isNewWorkspace)
              // if(isNewWorkspace) {
              //   console.log('isNewWorkspace In', isNewWorkspace)
              //   this.$root.$emit('workspace-changed')
              // }
            } else if (
              response.data.limit_reached &&
              response.data.full_name &&
              response.data.email
            ) {
              this.$bvModal.hide('create-workspace-modal')
              EventBus.$emit('workspace-limit-reached', {
                is_super_admin: response.data.is_super_admin,
                full_name: response.data.full_name,
                total_workspaces_limit: response.data.total_workspaces_limit,
                email: response.data.email,
              })
              this.$bvModal.show('workspace-limits-dialog')
            } else if (response.data.trial_expired) {
              this.alertMessage(response.data.message, 'error')
            } else if (response.data.error) {
              this.alertMessage('Unable to save workspace.', 'error')
            } else if (response.data.upgrade) {
              this.showUpgradeModal()
            } else if (response.data.message) {
              this.alertMessage(response.data.message, 'error')
            } else {
              this.alertMessage('Unable to save workspace.', 'error')
            }
            this.setSaveWorkspaceLoader(false)
          },
          (error) => {
            console.debug('Exception in saveWorkspace', error)
            this.setSaveWorkspaceLoader(false)
          }
        )
      }
    },

    checkWorkspaceRemovalAction(item) {
      console.log("METHOD::checkWorkspaceRemovalAction ~ item -> ", item)
      if (
        this.getWorkspaces &&
        this.getWorkspaces.items &&
        this.getWorkspaces.items.length === 1 &&
        item.default
      ) {
        this.confirmAction('removeWorkspace', item.workspace)
      } else if (item.default) {
        this.alertMessage('You can not remove your default workspace.', 'error')
      } else {
        this.confirmAction('removeWorkspace', item.workspace)
      }
    },

    /**
     * this method will remove workspace
     */
    removeWorkspace(confirmation, workspace) {
      console.debug(
        'Method:removeWorkspace',
        confirmation,
        workspace,
        this.getWorkspaces.items.length
      )

      if (confirmation.status) {
        this.setRemoveWorkspaceLoader(true)
        let onboarding = false
        if (this.$router.currentRoute.path === '/onboarding/createWorkspace') {
          onboarding = true
        }
        this.postRequest(
          removeWorkspaceUrl,
          {
            workspace_id: workspace._id,
          },
          async (response) => {
            if (response.data.status === true) {
              this.setConfirmActionStage('success')
              this.alertMessage('Workspace successfully removed.', 'success')
              const stateObject = this

              if (!onboarding) {

                EventBus.$emit('update-users-workspaces', { workspace_id: workspace._id, type: 'remove' });
                if (workspace._id === this.getWorkspaces.activeWorkspace._id) {
                  let workspace = this.getWorkspaces.items.find(
                    (item) => item.default === true
                  )
                  if (!workspace && this.getWorkspaces.items.length > 0)
                    workspace = this.getWorkspaces.items[0].workspace
                    await this.changeWorkspace(workspace.workspace, this.$route.name)
                    EventBus.$emit('fetch-latest-workspaces');
                }

                stateObject.Plan()
                if (
                  this.getWorkspaces &&
                  this.getWorkspaces.items &&
                  this.getWorkspaces.items.length === 0
                ) {
                  setTimeout(function () {
                    window.location.reload(true)
                  }, 2000)
                }
              } else {
                this.getOnboardingWorkspaces.forEach(function (el, index) {
                  if (el.workspace_id === workspace._id) {
                    stateObject.getOnboardingWorkspaces.splice(index, 1)
                    stateObject.$store.commit(
                      workspaceTypes.SET_ACTIVE_WORKSPACE,
                      workspace
                    )
                    stateObject.Plan()
                    return false
                  }
                })
                this.setOnboardingTeamIntilize(false)
              }
              this.setRemoveWorkspaceLoader(false)

            } else {
              if (response.data.message) {
                this.alertMessage(response.data.message, 'error')
              } else {
                this.alertMessage('Unable to remove workspace.', 'error')
              }
              this.setConfirmActionStage('fail')
            }

            this.setRemoveWorkspaceLoader(false)
            this.closeConfirmAction(confirmation.type)
          },
          (error) => {
            console.log('Exception in removeWorkspace')
            this.setConfirmActionStage('fail')
            console.debug('Exception in removeWorkspace', error)
            this.setRemoveWorkspaceLoader(false)
            this.closeConfirmAction(confirmation.type)
          }
        )
      } else {
        // eslint-disable-next-line
        $('#' + confirmation.type).modal('hide')
      }
    },

    /**
     * this method will fetch workspace details
     */
    processWorkspaceBasicSetting() {
      console.debug('Method:processWorkspaceBasicSetting')
      this.$store.commit(
        workspaceTypes.SET_WORKSPACE,
        JSON.parse(JSON.stringify(this.getWorkspaces.activeWorkspace))
      )
      // this.fetchWorkspaceLogoSuggessions()
    },

    /**
     * for billing related issues in workspaces
     */
    getWorkspaceLockMessage(workspace) {
      console.debug('Method:getWorkspaceLockMessage')

      if (typeof workspace.workspace !== 'undefined') {
        const workspaceDetails = workspace.workspace
        if (typeof workspaceDetails.super_admin_state !== 'undefined') {
          const superAdminState = workspaceDetails.super_admin_state
          let message = 'Workspace has been locked'
          switch (superAdminState) {
            case 'trial_finished':
              message += " because super admin's trial period has expired."
              break
            case 'canceled':
              message += ' because super admin has canceled their subscription.'
              break
            case 'disabled':
              message += ' because super admin has been disabled.'
              break
            case 'deleted':
              message += ' because super admin has been deleted.'
              break
            default:
              message += '.'
              break
          }

          return message
        }
      }
      return null
    },
    checkIfActiveWorkspaceHasPaymentIssue() {
      const workspaceDetails = this.getActiveWorkspace
      if (typeof workspaceDetails.has_payment_issue !== 'undefined') {
        return workspaceDetails.has_payment_issue
      }
      return false
    },
    checkIfWorkspaceHasPaymentIssue(workspace) {
      // if (workspace.workspace.name === 'Topviewsports') { return true }
      if (typeof workspace.workspace !== 'undefined') {
        const workspaceDetails = workspace.workspace
        if (typeof workspaceDetails.has_payment_issue !== 'undefined') {
          return workspaceDetails.has_payment_issue
        }
      }
      return false
    },
    checkIfActiveWorkspaceHasLimitExceeded() {
      const workspaceDetails = this.getActiveWorkspace
      if (typeof workspaceDetails.has_exceeded_limits !== 'undefined') {
        return workspaceDetails.has_exceeded_limits
      }
      return false
    },
    checkIfWorkspaceHasLimitExceeded(workspace) {
      if (typeof workspace.workspace !== 'undefined') {
        const workspaceDetails = workspace.workspace
        if (typeof workspaceDetails.has_exceeded_limits !== 'undefined') {
          return workspaceDetails.has_exceeded_limits
        }
      }
      return false
    },
    getActiveWorkspaceTotalLimits() {
      if (
        typeof this.getActiveWorkspace.usage_details !== 'undefined' &&
        this.getActiveWorkspace.usage_details
      ) {
        return this.getActiveWorkspace.usage_details.total
      } else {
        return {
          workspaces: 0,
          profiles: 0,
          blogs: 0,
          members: 0,
          automations: 0,
        }
      }
    },
    getActiveWorkspaceUsedLimits() {
      if (
        typeof this.getActiveWorkspace.usage_details !== 'undefined' &&
        this.getActiveWorkspace.usage_details
      ) {
        return this.getActiveWorkspace.usage_details.used
      } else {
        return {
          workspaces: 0,
          profiles: 0,
          blogs: 0,
          members: 0,
          automations: 0,
        }
      }
    },
    getActiveWorkspaceOverUsedStatus() {
      if (
        typeof this.getActiveWorkspace.usage_details !== 'undefined' &&
        this.getActiveWorkspace.usage_details
      ) {
        return this.getActiveWorkspace.usage_details.overused
      } else {
        return {
          workspaces: false,
          profiles: false,
          blogs: false,
          members: false,
          automations: false,
          media_storage: false,
        }
      }
    },

    /**
     * this method will change environment to selected workspace
     */
    async shouldChangeWorskspace(workspace, routeName) {
      // if (this.checkIfWorkspaceHasPaymentIssue(workspace)) {
      //   console.log('Workspace has payment issue')
      // } else {
      console.log('shouldChangeWorskspace', workspace)

      await this.changeWorkspace(workspace.workspace, routeName)
      EventBus.$emit('workspace-changed')
      // }
    },
    async changeWorkspace(workspace, routeName, findBy = '_id', hash = '') {
      console.log('Changing workspace:', workspace)
      EventBus.$emit('set-white-label-settings', workspace?.whitelabel)
      this.isWorkspaceLoading = true

      let params = {};
      if(findBy === '_id'){
        params = { id: workspace._id }
      }else{
        params = { slug: findBy, is_default: findBy === 'is_default' }
      }

        try {
          const response = await proxy.post(fetchWorkspaceUrl, params)
          if (response.data.status === true) {
            const fetchedWorkspace = response.data.item?.workspace
            const activeWorkspaceMember = this.getLoggedUserRole(fetchedWorkspace)

            if (activeWorkspaceMember && activeWorkspaceMember.role === 'approver') {
              routeName = 'planner_feed_v2'
            }

            await this.removeWorkspaceSocketListener()

            this.$store.commit(workspaceTypes.SET_WORKSPACE_SEARCH, '')

            this.fetchCommonWorkspaceData(fetchedWorkspace)
            this.processWorkspaceBasicSetting()

            if(hash != ''){
              await this.$router.push({
                name: routeName,
                params: { workspace: fetchedWorkspace.slug },
                hash: hash,
              })
            }else{
              await this.$router.push({
                name: routeName,
                params: { workspace: fetchedWorkspace.slug },
              })
            }




            await this.Plan()


          }
        } catch (error) {
          console.error('Error changing workspace:', error)
        } finally {
          this.isWorkspaceLoading = false
        }

    },

    async fetchCommonWorkspaceData(activeWorkspace) {
      console.debug('Fetching common workspace data' , activeWorkspace)
      await this.resetDefaultStates()

      // this.changeDefaultWorkspace(activeWorkspace)
      console.log('SET_ACTIVE_WORKSPACE')
      this.$store.commit(workspaceTypes.SET_ACTIVE_WORKSPACE, activeWorkspace)

      console.debug('Method:processActiveWorkspace')

      await Promise.all([
        this.$store.dispatch('fetchBlogs'),
        this.$store.dispatch('fetchSocialAccounts'),
        this.$store.dispatch('fetchIntegrationsAccounts'),
        this.$store.dispatch('fetchMobileDevices'),
        this.$store.dispatch('fetchContentCategories'),
        this.$store.dispatch('fetchContentCategoriesSlots'),
        this.$store.dispatch('fetchLabels'),
        this.$store.dispatch('fetchWorkspaceHashtags'),
        this.$store.dispatch('fetchContentCategories')
      ])

      if (this.getPublicationFolderStatus === false) {
        this.fetchPublicationFolders(),
        this.$store.commit(composer.SET_PUBLICATION_FOLDER_STATUS, true)
      }

      this.initializeHashtags()
      // if workspace not found in list, then navigate to workspace list page
      if (!activeWorkspace) {
        return this.$router.push({
          name: 'workspaces',
        })
      }

      activeWorkspace?.members?.sort((a, b) => {
        if (a.user?.firstname && b.user?.firstname) {
          return a.user.firstname
            .toLowerCase()
            .localeCompare(b.user.firstname.toLowerCase())
        } else if (a.user?.firstname === null) {
          return 1
        } else if (b.user?.firstname === null) {
          return -1
        }
      })

      setTimeout(async () => {
        await this.initializeSection(false)
        await this.identify()
        // this.initializeDiscoveryWorkspaceChange(routeName)
      }, 500)

      if (this.getProfile.state === 'hold') {
        return this.$router.push({
          name: 'plan',
          params: { workspace: activeWorkspace.slug },
        })
      }
      // if the web page is only main one then redirect to the cover stories page
      if (this.$route.query.is_shopify && this.$route.query.is_shopify) {
        return this.$router.push({
          name: 'blogs',
          params: { workspace: activeWorkspace.slug },
          hash: '#shopify',
        })
      }

      const DEFAULT_ROUTE = 'planner_v2'

      // Check if approver has the permission for the composer view
     if (this.checkForApproverEditPostCase()) return

      // Check if has permission for the default planner view
      if (this.getLoggedUser.role === WORKSPACE_ROLES.APPROVER && this.hasRoutePermission(this.getProfile.planner_default_view)) {
        // If has permission and is not in approver edit post case
          const targetRoute = APPROVER_ALLOWED_ROUTES.includes(this.$route.name) ? this.$route.name : DEFAULT_ROUTE;
          return this.navigateToRoute(targetRoute, activeWorkspace.slug);
      }

      // Redirect to default planner view if does not have permission
      if (this.$route.name === 'setPassword' && !this.getProfile?.need_to_reset_password) {
        return this.navigateToRoute(DEFAULT_ROUTE, activeWorkspace.slug);
      }

      if (!this.hasRoutePermission(this.$route.name)) {
        return this.navigateToRoute(DEFAULT_ROUTE, activeWorkspace.slug);
      }

      if (['home', 'login'].includes(this.$route.name)) {
        if (this.checkIfActiveWorkspaceHasPaymentIssue()) {
          return this.$router.push({
            name: 'workspaces',
          })
        } else {
          return this.$router.push({
            name: 'dashboard',
            params: { workspace: activeWorkspace.slug },
          })
        }
      }
      return true

    },

    getLoggedUserRole(workspace) {
      console.debug('workspace', workspace)
      const user = this.getProfile._id
      let member = null
      // finding member from states
      if (user && workspace && workspace._id && workspace.members) {
        member = workspace.members.find((item) => {
          return item.user_id === user
        })
      }
      return member
    },

    /**
     * this method will change default workspace
     */
    changeDefaultWorkspace(workspace, bvModal = null) {
      console.debug('Method:changeDefaultWorkspace')
      const stateObject = this
      this.setChangeDefaultWorkspaceLoader(true)
      this.postRequest(
        changeDefaultWorkspaceUrl,
        {
          workspace_id: workspace._id,
        },
        (response) => {
          if (response.data.status === true) {
            EventBus.$emit('update-users-workspaces', { workspace_id: workspace._id, type: 'update', fields: { default: true } });
            if(bvModal) bvModal.hide(workspace._id)
            // this.getWorkspaces.items.forEach(function (el, index) {
            //   if (el.workspace_id === workspace._id) {
            //     stateObject.getWorkspaces.items[index].default = true
            //     if(bvModal) bvModal.hide(workspace._id)
            //   } else {
            //     stateObject.getWorkspaces.items[index].default = false
            //   }
            // })
          } else if (response.data.message) {
            this.alertMessage(response.data.message, 'error')
          } else {
            this.alertMessage('Unable to change default workspace.', 'error')
          }

          this.setChangeDefaultWorkspaceLoader(false)
        },
        (error) => {
          console.debug('Exception in changeDefaultWorkspace', error)
          this.setChangeDefaultWorkspaceLoader(false)
        }
      )
    },

    /**
     * this method will update default workspace member detail
     */
    changeDefaultWorkspaceMemberDetail(members) {
      console.debug('Method:changeDefaultWorkspaceMemberDetail')
      const activeWorkspace = this.getWorkspaces.activeWorkspace
      activeWorkspace.members = members
      console.debug(activeWorkspace)
      this.$store.commit(
        workspaceTypes.SET_ACTIVE_WORKSPACE,
        JSON.parse(JSON.stringify(activeWorkspace))
      )
    },

    /**
     * this method will update all workspace member detail
     */
    changeWorkspaceMemberDetail(workspaceId, members) {
      console.debug('Method:changeDefaultWorkspaceMemberDetail')
      const allWorkspaces = this.getWorkspaces.items
      allWorkspaces.map((item) => {
        if (item.workspace._id === workspaceId) {
          item.workspace.members = members
        }
      })

      this.$store.commit(
        workspaceTypes.SET_WORKSPACE_ITEMS,
        JSON.parse(JSON.stringify(allWorkspaces))
      )
    },

    async processActiveWorkspace(workspaces, reset = true) {

      console.debug('Method:processActiveWorkspace')

      return;

      let activeWorkspace = false

      let workspace = window.location.href.split('/')[3] // this.$route.path.substr(1)
      workspace = decodeURIComponent(workspace.replace(/\+/g, ' '))

      if (workspace) {
          activeWorkspace = workspaces.find(element => element.workspace.slug === workspace)?.workspace || null;
      } else {
          activeWorkspace = workspaces.find(element => element.default)?.workspace || null;
      }

      if(!activeWorkspace && workspaces.length > 0) {
        activeWorkspace = workspaces[0].workspace
        this.changeDefaultWorkspace(activeWorkspace)
      }

      // if workspace not found in list, then navigate to workspace list page
      if (!activeWorkspace) {
        return this.$router.push({
          name: 'workspaces',
        })
      }
        EventBus.$emit('set-white-label-settings', activeWorkspace?.whitelabel)
      activeWorkspace?.members?.sort((a, b) => {
        if (a.user?.firstname && b.user?.firstname) {
          return a.user.firstname
            .toLowerCase()
            .localeCompare(b.user.firstname.toLowerCase())
        } else if (a.user?.firstname === null) {
          return 1
        } else if (b.user?.firstname === null) {
          return -1
        }
      })
      console.log('SET_ACTIVE_WORKSPACE')
      // active workspace scenario

      this.$store.commit(workspaceTypes.SET_ACTIVE_WORKSPACE, activeWorkspace)



      if (reset) await this.resetDefaultStates()

      this.fetchCommonWorkspaceData();

      // this.$store.dispatch('fetchBlogs')
      // this.$store.dispatch('fetchSocialAccounts')
      // this.$store.dispatch('fetchIntegrationsAccounts')

      // mobile devices
     // this.$store.dispatch('fetchMobileDevices')

      // if (this.getPublicationFolderStatus === false) {
      //   this.fetchPublicationFolders()
      //   this.$store.commit(composer.SET_PUBLICATION_FOLDER_STATUS, true)
      // }

      this.initializeHashtags()
   //   this.$store.dispatch('fetchLabels')
      // await this.fetchDiscover()

      this.initializeSection()

      this.$store.dispatch('fetchContentCategories')

      if (this.getProfile.state === 'hold') {
        return this.$router.push({
          name: 'plan',
          params: { workspace: activeWorkspace.slug },
        })
      }
      // if the web page is only main one then redirect to the cover stories page
      if (this.$route.query.is_shopify && this.$route.query.is_shopify) {
        return this.$router.push({
          name: 'blogs',
          params: { workspace: activeWorkspace.slug },
          hash: '#shopify',
        })
      }

      const DEFAULT_ROUTE = 'planner_v2'

      // Check if approver has the permission for the composer view
      if (this.checkForApproverEditPostCase()) return

      // Check if has permission for the default planner view
      if (this.getLoggedUser.role === WORKSPACE_ROLES.APPROVER && this.hasRoutePermission(this.getProfile.planner_default_view)) {
        // If has permission and is not in approver edit post case
          const targetRoute = APPROVER_ALLOWED_ROUTES.includes(this.$route.name) ? this.$route.name : DEFAULT_ROUTE;
          return this.navigateToRoute(targetRoute, activeWorkspace.slug);
      }

      // Redirect to default planner view if does not have permission
      if (this.$route.name === 'setPassword' && !this.getProfile?.need_to_reset_password) {
        return this.navigateToRoute(DEFAULT_ROUTE, activeWorkspace.slug);
      }

      if (!this.hasRoutePermission(this.$route.name)) {
        return this.navigateToRoute(DEFAULT_ROUTE, activeWorkspace.slug);
      }

      if (['home', 'login'].includes(this.$route.name)) {
        if (this.checkIfActiveWorkspaceHasPaymentIssue()) {
          return this.$router.push({
            name: 'workspaces',
          })
        } else {
          return this.$router.push({
            name: 'dashboard',
            params: { workspace: activeWorkspace.slug },
          })
        }
      }
      return true
    },

    navigateToRoute(routeName, slug) {
      return this.$router.push({
        name: routeName,
        params: { workspace: slug },
        query: { ...this.$route.query },
      }).catch((err) => {
        if (err.name !== 'NavigationDuplicated') throw err;
      });
    },

    checkForApproverEditPostCase() {
      const user = this.getLoggedUser
      if (this.$route.name === 'social-modal') {
        if (
          user.role === 'approver' &&
          !user.permissions?.approverCanEditPost
        ) {
          this.alertMessage(
            'You are not authorized to access this page.',
            'error'
          )
          return false
        }
        return true
      }
      return false
    },

    getWorkspaceLogoById(id) {
      let logo = null
      this.getOnboardingWorkspaces.forEach(function (el, index) {
        if (el.workspace_id === id) {
          logo = el.workspace.logo
          return logo
        }
      })
      return logo
    },

    async removeWorkspaceSocketListener() {
      const stateObject = this
      /* eslint-disable no-undef */
      socket.removeListener(
        'article_plan_job:' + stateObject.getWorkspaces.activeWorkspace._id
      )
      socket.removeListener(
        'video_plan_job:' + stateObject.getWorkspaces.activeWorkspace._id
      )
      socket.removeListener(
        'rss_plan_job:' + stateObject.getWorkspaces.activeWorkspace._id
      )
      socket.removeListener(
        'bulk_csv_upload:' + stateObject.getWorkspaces.activeWorkspace._id
      )
      socket.removeListener(
        'bulk_evergreen_upload:' + stateObject.getWorkspaces.activeWorkspace._id
      )
      socket.removeListener(
        'evergreen_plan_job:' + stateObject.getWorkspaces.activeWorkspace._id
      )
      socket.removeListener(
        'content_rewriting_processing:' +
          stateObject.getWorkspaces.activeWorkspace._id
      )
      // socket.removeListener('composer_editing:' + stateObject.getWorkspaces.activeWorkspace._id)
      window.webSockets.removeListener(
        'composer_editing:' + stateObject.getWorkspaces.activeWorkspace._id
      )
      socket.removeListener(
        'process_activity:' + stateObject.getWorkspaces.activeWorkspace._id
      )
      /* eslint-enable no-undef */
    },

    getMember(id) {
      return this.getWorkspaces.activeWorkspace.members.find(
        (member) => member.user_id === id
      )
    },

    getTeamMembersIds(members) {
      const ids = []
      members.forEach((workspaceMember, index) => {
        if (workspaceMember.status === 'joined' && workspaceMember.user) {
          ids.push(workspaceMember.user._id)
        }
      })
      return ids
    },

    async onboardingStepsCompleted(step = null) {
      if (step) {
        await this.postRequest(
          onboardingStepsURL,
          {
            workspace_id: this.getWorkspaces.activeWorkspace._id,
            step,
          },
          (response) => {
            if (response.data.status === true) {
              this.$store.commit(
                workspaceTypes.SET_ACTIVE_WORKSPACE_ONBOARDING_STEPS,
                response.data.steps
              )
            }
          },
          (error) => {
            console.error('ERROR IN ONBOARDING STEP', error)
          }
        )
      }
    },
    async onboardingCompleted(status = true) {
      await this.postRequest(
        onboardingCompleteURL,
        {
          workspace_id: this.getWorkspaces.activeWorkspace._id,
          status,
        },
        (response) => {
          if (response.data.status === true) {
            this.getActiveWorkspace.onboarding = true
            this.$bvModal.hide('removeOnboardingWidget')
          }
          this.trackUserMaven('onboarding_completed')
        },
        (error) => {
          console.log('ERROR IN ONBOARDING COMPLETED', error)
        }
      )
    },
    async neverSeeOnboardingWidget(
      status = true,
      widgetStatusForWorkspaces = false
    ) {
      await this.postRequest(
        onboardingWidgetURL,
        {
          workspace_id: this.getWorkspaces.activeWorkspace._id,
          status,
          widget_status: widgetStatusForWorkspaces,
        },
        (response) => {
          if (response.data.status === true) {
            this.fetchWorkspaces(true)
            this.$bvModal.hide('removeOnboardingWidget')
          }
          this.trackUserMaven('onboarding_completed')
        },
        (error) => {
          console.log('ERROR IN ONBOARDING COMPLETED', error)
        }
      )
    },
    isUserTrialPlan() {
      if (this.getPlan?.subscription?.slug) {
            return this.getPlan.subscription.slug.includes('trial')
      }
      return false
    },
    getWorkspaceSuperAdminDetails() {
      if (
        this.getWorkspaces.activeWorkspace &&
        this.getWorkspaces.activeWorkspace.members
      ) {
        const superAdmin = this.getWorkspaces.activeWorkspace.members.find(
          (member) => member.role === 'super_admin'
        )
        if (superAdmin) {
          return superAdmin.user
        }
      }

      return null
    },
  },
}
